import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = []
const hostname = location.hostname
switch (hostname) {
  case 'lottery03.princekeitamachida.tw':
  case 'exile.princekeitamachida.tw':
    routes = [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "mainlayout" */ '../layouts/Main.vue'),
        children: [
          {
            path: '',
            name: 'Exile',
            component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
          }
        ]
      }
    ]
    break
  default:
  case 'lottery02.princekeitamachida.tw':
  case 'drama.princekeitamachida.tw':
    routes = [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "mainlayout" */ '../layouts/Main.vue'),
        children: [
          {
            path: '',
            name: 'Drama',
            component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
          }
        ]
      }
    ]
    break

  case 'course.princekeitamachida.tw':
  case 'lottery05.princekeitamachida.tw':
    routes = [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "mainlayout" */ '../layouts/Main.vue'),
        children: [
          {
            path: '',
            name: 'Home',
            component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
          }
        ]
      }
    ]
    break
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
